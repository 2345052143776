import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import { Features } from '../../../static/data/access-control'
import * as Styles from 'styles/multi-cdn.module.css'
import { AugmentCoomponent } from './api-protection'

const PulsePage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('accessControl.meta.title'),
            metaDescription: t('accessControl.meta.description'),
            metaUrl: 'https://www.mlytics.com/features/access-control/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />

        <section className="md:flex-row ">
          <div className="container flex flex-col-reverse lg:flex-row gap-5 py-20 mb-20">
            <div className="flex-1 flex flex-col items-center justify-center  lg:block ">
              <h1
                style={{ fontWeight: '300 !important' }}
                className="mb-2 font-light mt-2 leading-loose text-center md:text-start text-lg md:text-base  text-primary-400"
              >
                {t('accessControl.section1.subTitle')}
              </h1>
              <h2 className="text-4xl lg:text-[3.2em] text-center lg:text-start text-primary-500 mb-4 leading-snug">
                {t('accessControl.section1.title')}
              </h2>
              <p className="pb-5  leading-snug lg:text-start text-center text-xl text-gray-500  mb-5">
                <Trans i18nKey={'accessControl.section1.content'}></Trans>
              </p>
              <div className="flex flex-col md:flex-row">
                <Link
                  to="/sign-up"
                  className="min-w-[280px] md:min-w-[200px] mb-4 md:mb-0 md:mr-4 btn btn-bold btn-orange "
                  style={{ padding: '12px 28px' }}
                >
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn-bold min-w-[280px]  md:min-w-[200px]  btn btn-ghost  btn-orange  "
                  style={{ padding: '12px 28px' }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
            <div className="md:flex flex-col flex-1 items-center overflow-hidden hidden md:mb-0 mb-4">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2022/10/Access_control_hero.webp"
                alt={t('accessControl.section1.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
          </div>
        </section>

        <section className="bg-primary-500  ">
          <div
            className={`py-24 flex flex-col justify-center items-center ${Styles.container}`}
            // style={{ 'padding-left': 'calc((100vw - 800px)/2)', 'padding-right': 'calc((100vw - 800px)/2)' }}
          >
            <h3 className="relative text-[1.5em] md:text-[2.3em] inline-block w-auto text-white text-center mb-8">
              {t('accessControl.section2.title')}
              <span className="absolute text-6xl top-0 text-white translate-y-50 hidden lg:block  left-[-40px]">{`"`}</span>
              <span className="absolute text-6xl top-0 text-white translate-y-50 hidden lg:block right-[-40px]">{`"`}</span>
            </h3>
            <p className="text-white text-center mb-8 leading-relaxed">{t('accessControl.section2.content1')}</p>
          </div>
        </section>
        <section className="bg-gray-50">
          <div className="container grid md:grid-cols-3 grid-cols-1 gap-8 md:gap-3 py-20">
            {Features.map(({ title, image, content }, key) => (
              <div className="flex flex-col text-center items-center justify-center" key={`${key}_FeatureCard`}>
                <img className="mb-3 w-[140px]" src={image} alt={t(title)} />
                <h4 className="mb-6 text-3xl text-primary-500">{t(title)}</h4>
                <p className="text-gray-600 leading-loose">{t(content)}</p>
              </div>
            ))}
          </div>
        </section>
        <section className=" ">
          <div className="container flex flex-col-reverse md:flex-row gap-5 md:gap-10 py-20">
            <div className="flex-1 md:mt-5 relative md:block ">
              <p className="mb-1 font-black pt-2  absolute top-[-35px] text-center md:text-start text-base text-primary-500">
                {t('accessControl.section4.subTitle')}
              </p>
              <h2 className="text-xl lg:text-3xl">{t('accessControl.section4.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">
                <Trans i18nKey={'accessControl.section4.content'}>
                  <Link to="/features/multi-cdn" className="text-secondary-600"></Link>
                </Trans>
              </p>
            </div>
            <div className="flex flex-col flex-1 overflow-hidden md:mb-0 mb-8">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2022/10/Access_control-1.webp"
                alt={t('accessControl.section4.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
          </div>
        </section>
        <section className="container flex flex-col-reverse md:flex-row-reverse gap-5 md:gap-10 pb-20">
          <div className="flex-1 relative  md:block md:mt-4">
            <p className="mb-1 font-black pt-2  absolute top-[-35px] text-center md:text-start text-base text-primary-500">
              {t('accessControl.section5.subTitle')}
            </p>
            <h2 className="text-xl lg:text-3xl">{t('accessControl.section5.title')}</h2>
            <p className="pb-7 pt-2 text-gray-500">
              <Trans i18nKey={'accessControl.section5.paragraph1'}></Trans>
            </p>
            <p className="pb-7 pt-2 text-gray-500">
              <Trans i18nKey={'accessControl.section5.paragraph2'}>
                <a href="#" className="font-bold cursor-default pointer-events-none "></a>
                <a href="#" className="font-bold cursor-default pointer-events-none "></a>
              </Trans>
            </p>
          </div>
          <div className="flex flex-col flex-1  overflow-hidden md:mb-0 mb-8">
            <img
              src="https://www.mlytics.com/wp-content/uploads/2022/10/Access_control-2_01.webp"
              alt={t('accessControl.section5.title')}
              style={{ height: 'auto', maxWidth: '550px' }}
            />
          </div>
        </section>

        <AugmentCoomponent />
      </React.Fragment>
    </Layout>
  )
}

export default PulsePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
